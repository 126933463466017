import React, { ReactElement, useEffect, useMemo } from "react";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import { GridCellProps } from "@progress/kendo-react-grid";
import { GridField, TranslatedApiValueCell } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import DateCell from "../../../../Components/DateCell";
import { fetchUnavailabilitiesByResource } from "../../../timeseries/unavailability/store/thunks";

const UnavailabilityTable = (): ReactElement => {

    const { allUnavailabilities } = useAppSelector((state) => state.unavailabilities);
   
    const { currentResource } = useAppSelector((state) => state.controllableResources);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables");

    useEffect(() => {
        if (allUnavailabilities === undefined && currentResource != null) {
            dispatch(fetchUnavailabilitiesByResource({ id: currentResource.inventoryItemId!, force: false }));
        }
    }, [allUnavailabilities, currentResource, dispatch]);

    const columns = useMemo(
        () => [
            {
                title: t("unavailability.start"),
                customCell: (props: GridCellProps) => (
                    <DateCell {...props} fieldPath="start" displayFormat={DateTime.DATETIME_SHORT} />
                ),
            },
            {
                title: t("unavailability.end"),
                customCell: (props: GridCellProps) => (
                    <DateCell {...props} fieldPath="end" displayFormat={DateTime.DATETIME_SHORT} />
                ),
            },

            {
                title: t("unavailability.reason"),
                customCell: ({ dataItem }: { dataItem: any }) => (
                    <td>{` ${dataItem.reason?.name}`}</td>
                )
            },
            {
                title: t("unavailability.businessType"),
                customCell: ({ dataItem }: { dataItem: any }) => (
                    <td>{`${dataItem.businessType?.code}: ${dataItem.businessType?.name}`}</td>
                )
            },
            {
                name: "type.name",
                title: t("unavailability.type"),
                customCell: ({ dataItem }: { dataItem: any }) => (
                    <TranslatedApiValueCell value={dataItem.type?.code} namespace="api:unavailability_type" />
                ),
            },
        ],
        [t],
    );

    return (
        <GridField
            noRecordsText={t("no_records_text")}
            defaultOpen
            dataItemKey="internalID"
            values={allUnavailabilities}
            onRowClick={(event) =>
                history.push(`/controllable/${currentResource?.externalID}/unavailability/${event.dataItem.internalID}`)
            }
            gridFields={columns}
        />
    );
};

export default UnavailabilityTable;
