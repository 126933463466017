import { Field, isFieldValid, LoadingComponent } from "ndr-designsystem";
import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { setFinalDecommissioningDate, setPreliminaryDecommissioningDate } from "./store/technicalResourcesSlice";
import SchemaConstants from "../../utils/schemaConsts";
import { PageStatus } from "../../utils/types";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { TROperationSchema } from "../../utils/inputChecking";

const OperationTab = ({ pageStatus }: { pageStatus: PageStatus }): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables");
    const { currentResource: resource } = useAppSelector(state => state.technicalResources);
    const OperationTabSchema = useMemo(() => TROperationSchema(), []);

    if (resource === null) {
        return <>
            Invalid resource id
        </>;
    }

    if (resource === undefined) {
        return <LoadingComponent/>
    }

    return (
        <>
            <Field
                onValueChange={val => dispatch(setPreliminaryDecommissioningDate(val))}
                inputType='date'
                isEditing={isEditing || isAdding}
                valueCheckFunction={preliminaryDecommissioningDate => isFieldValid(OperationTabSchema, [SchemaConstants.preliminaryDecommissioningDate], { preliminaryDecommissioningDate })}
                defaultValue={resource.preliminaryDecommissioningDate}
                title={t("technical_resources_details.preliminaryDecommissioningDate")}
            />
            <Field
                onValueChange={val => dispatch(setFinalDecommissioningDate(val))}
                inputType='date'
                isEditing={isEditing || isAdding}
                valueCheckFunction={finalDecommissioningDate => isFieldValid(OperationTabSchema, [SchemaConstants.finalDecommissioningDate], { finalDecommissioningDate })}
                defaultValue={resource.finalDecommissioningDate}
                title={t("technical_resources_details.finalDecommissioningDate")}
            />
        </>
    )
}

export default OperationTab