import { createAsyncThunk } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import { RootState } from "../../../../app/store";
import { removeNulls } from "../../../../utils";
import Api from "../../../../api/Api";
import UnavailabilityDto from "../../../../api/dtos/Unavailability/UnavailabilityDto";
import UnavailabilityDetailsDto from "../../../../api/dtos/Unavailability/UnavailabilityDetailsDto";
import { fetchAllControlGroupsAndResources } from "../../../appStateSlice";
import { fetchControllableResourceByIdAction } from "../../../controllableResources/store/thunks";
import { setAllUnavailabilities, setCurrentUnavailability } from "./store";

export const fetchUnavailabilitiesByResource = createAsyncThunk(
    "unavailability/fetchByControllableResourceId",
    async ({ id, force }: { id: string, force: boolean }, { getState, dispatch }) => {
        let response: UnavailabilityDto[] | undefined = [];
        const state = getState() as RootState;

        response = state.unavailabilities.allUnavailabilities;

        if (response === undefined || response.length === 0 || force) {
            response = (await Api.fetchUnavailabilitiesByControllableResource(id)).data;
            response = orderBy(response.map(un => removeNulls(un)), ['start', 'end'], ['desc', 'desc']);
        }

        await dispatch(setAllUnavailabilities(response));
    }
)

export const fetchUnavailabilityDetailsById = createAsyncThunk(
    "unavailability/fetchById",
    async ({ CRID, id, force }: { CRID: string, id: string, force: boolean }, { getState, dispatch }) => {
        let response: UnavailabilityDetailsDto | undefined;
        const state = getState() as RootState;
        response = state.unavailabilities.currentUnavailability;

        if (!response || force) {
            response = removeNulls((await Api.fetchUnavailabilityById(CRID, id)).data);
        }

        await dispatch(setCurrentUnavailability(response));
    }
)

export const resetUnavailabilitiesState = createAsyncThunk(
    "unavailability/resetState",
    async (_: never, { dispatch }) => {
        await dispatch(setCurrentUnavailability(undefined))
    })

export const buildUnavailabilitiesState = createAsyncThunk(
    "unavailability/buildState",
    async ({
               controllableId,
               seriesId,
               force
           }: { controllableId: string, seriesId: string, force: boolean }, { dispatch }) => {
        await dispatch(fetchAllControlGroupsAndResources({ force, fetchResources: true, fetchGroups: false }))

        const controllableResource = await dispatch(fetchControllableResourceByIdAction({
            id: controllableId,
            force,
            setAsCurrent: true
        })).unwrap();

        if (controllableResource && controllableResource.inventoryItemId) {
            await dispatch(fetchUnavailabilitiesByResource({ id: controllableResource.inventoryItemId, force }));
            await dispatch(fetchUnavailabilityDetailsById({
                CRID: controllableResource.inventoryItemId,
                id: seriesId,
                force
            }));
        }
    }
)


