import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GridField, LoadingComponent, TranslatedApiValueCell, useLocalStorage } from "ndr-designsystem";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridFieldElement } from "ndr-designsystem/dist/utils";
import { isEqual } from "lodash";
import TimeSeriesGrid from "./Grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { buildUnavailabilitiesState, resetUnavailabilitiesState, } from "./store/thunks";
import { ToggleViewButton } from "../ToggleViewButton";
import useStyles from "../styles";
import TimeSeriesChart from "./Chart";
import { allUnavailabilitiesSelector, currentUnavailabilitySelector } from "./store/store";
import Navigation from "../Navigation";

export type TimeSeriesViewType = "grid" | "chart"

interface RouteParams {
    controllableId: string,
    seriesId: string
}

const DataView = (): ReactElement => {
    const { controllableId, seriesId } = useParams<RouteParams>();
    const { headerHeight } = useAppSelector(state => state.appState)
    const [currentlyViewing, setCurrentlyViewing] = useLocalStorage<TimeSeriesViewType>("unavailability.view", {
        pollingInterval: -1,
        initialValue: "chart"
    })
    const { currentResource: controllableResource } = useAppSelector(state => state.controllableResources)
    const allUnavailabilities = useAppSelector(allUnavailabilitiesSelector, (left, right) => isEqual(left, right))
    const history = useHistory();
    const currentUnavailability = useAppSelector(currentUnavailabilitySelector, (left, right) => left?.internalID === right?.internalID);
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const { t } = useTranslation("tables");

    useEffect(() => {
        dispatch(buildUnavailabilitiesState({ controllableId, seriesId, force: false }))
        return () => dispatch(resetUnavailabilitiesState())
        // we need to trigger this only when technicalId gets available (at the same time with controllableId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seriesId])

    const Toolbar = useCallback((): ReactElement => <div className={classes.toolbar}>
        <ToggleViewButton view={currentlyViewing} onToggleView={setCurrentlyViewing}/>
    </div>, [currentlyViewing, classes, setCurrentlyViewing]);

    const getGridFields = useMemo((): GridFieldElement[] => [
        {
            name: "reason.name",
            title: t("controllable_resources_details.unavailabilities.reason_code")
        },
        {
            name: "processType.name",
            title: t("controllable_resources_details.unavailabilities.process_type"),
            customCell: ({ dataItem }) => <TranslatedApiValueCell
                namespace="api:process_type"
                value={dataItem.processType?.code}
            />,
        },
        {
            name: "businessType.name",
            title: t("controllable_resources_details.unavailabilities.business_type"),
            customCell: ({ dataItem }) => <TranslatedApiValueCell
                namespace="api:business_type"
                value={dataItem.businessType?.code}
            />
        },
    ], [t])

    if (!currentUnavailability) return <LoadingComponent/>

    return (
        <Box display="flex" flexDirection="column"
             height={`calc(100vh - ${headerHeight + 250}px)`} width="100%">
            <Navigation
                allElements={allUnavailabilities ?? []} currentElement={currentUnavailability}
                elementKey="internalID"
                onNextClick={e => {
                    history.push(`/controllable/${controllableResource?.externalID}/unavailability/${e.internalID}`)
                }}
                onPreviousClick={e => {
                    history.push(`/controllable/${controllableResource?.externalID}/unavailability/${e.internalID}`)
                }}
            />
            <GridField
                noRecordsText={t("no_records_text")}
                pageable={false}
                gridFields={getGridFields}
                defaultOpen
                take={10}
                values={[currentUnavailability]}
            />
            <Toolbar/>
            {currentlyViewing === "grid" ?
                <TimeSeriesGrid/> :
                <TimeSeriesChart/>
            }
        </Box>
    )

}

export default DataView;
